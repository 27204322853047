import { NetFetch } from '../Net/NetFetch';
import { UnauthorizedError } from './Error/UnauthorizedError';
import { StatusCodes } from './Status/StatusCodes';
/**
 * Group
 */
export class Group {
    /**
     * getGroupList
     */
    static async getGroupList() {
        const result = await NetFetch.getData('/json/group/list');
        if (result && result.statusCode) {
            const tresult = result;
            switch (tresult.statusCode) {
                case StatusCodes.OK:
                    if (tresult.list) {
                        return tresult.list;
                    }
                    throw new Error('Grouplist is empty return!');
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return null;
    }
}
