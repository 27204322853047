import { NetFetch } from '../Net/NetFetch';
import { UnauthorizedError } from './Error/UnauthorizedError';
import { StatusCodes } from './Status/StatusCodes';
/**
 * User
 */
export class User {
    /**
     * getCurrentUser
     */
    static async getUserInfo() {
        const result = await NetFetch.getData('/json/user/info');
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK:
                    return result.data;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return null;
    }
    /**
     * getUserList
     */
    static async getUserList() {
        const result = await NetFetch.getData('/json/user/list');
        if (result && result.statusCode) {
            const tresult = result;
            switch (result.statusCode) {
                case StatusCodes.OK:
                    if (tresult.list) {
                        return tresult.list;
                    }
                    throw new Error('Userlist is empty return!');
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return null;
    }
    /**
     * save
     * @param user
     */
    static async save(user) {
        const result = await NetFetch.postData('/json/user/save', user);
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK:
                    return true;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
                case StatusCodes.INTERNAL_ERROR:
                    throw new Error(result.msg);
            }
        }
        return false;
    }
    /**
     * saveNewPassword
     * @param password
     */
    static async saveNewPassword(password) {
        const result = await NetFetch.postData('/json/user/savepassword', password);
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK:
                    return true;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
                case StatusCodes.INTERNAL_ERROR:
                    throw new Error(result.msg);
            }
        }
        return false;
    }
    /**
     * saveNewPin
     * @param pin
     */
    static async saveNewPin(pin) {
        const result = await NetFetch.postData('/json/user/savepin', pin);
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK:
                    return true;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
                case StatusCodes.INTERNAL_ERROR:
                    throw new Error(result.msg);
            }
        }
        return false;
    }
}
